.googleMap{
    margin-bottom:2%;
    width: 100%;
    height: 200px;
}

.info{
    display: flex;
    justify-content: flex-start;
    margin-top: 5%;
}

.address , .email, .phone{
    height:3rem;
    width:3rem;
    margin-right: 5px;
}
.locationIcon{
    margin-left: 20%;
    cursor: pointer;
    color: red;
}
.emailIcon{
}
.mobIcon{
}
.contact-form{
   padding-top: 6;
}