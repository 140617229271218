.liveRate .header{
    font-size: 16px;
    font-weight: 700;
    color: forestgreen;
}
.liveRate .date{
    font-size: 12px;
    color: black;
    font-weight: 600;
}
#liveRateTable{
    font-size: 14px;
    border: 1px solid black;
    background-color: antiquewhite;
}
#liveRateTable thead{
    color: red;
}
#liveRateTable tbody{
    font-weight: 600;
}
.liveRate{
    background-image: url('../../assets/images/background_image_1.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;  
}