.navHeader{
    padding-left: 20% !important;
}
.custName{
    font-size: 2.5rem !important;
    margin-left: 5%;
    display: inline-block;
}
.custLogo{
    border-radius: 10px;
    margin-top: -16px;
}
.specialTab{
    color: red;
    font-weight: 700;
    animation-name: special;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    border-radius: 100px / 35px;
}
@keyframes special {
    from {color: red;}
    to {color: gold;}
}