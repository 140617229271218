.home marquee{
    /* color: #FFC451; */
    color: silver;
    font-size: 1rem;
    font-weight: 700;
}
.home{
    height: 70%;
}
.home .heading{
    padding: 12.5% 14%;
    color: silver;  
    font-weight: 700;
}
.home .goldRate{
    font-size: 16px;
    float: left;
    color: gold;
    font-weight: 700;
}
#ReactBackgroundSlider{
    /* opacity: unset; */
}