
.retailerBlock{
    box-sizing: border-box;
    cursor: default;
    background: #f5f5f5;
    padding: 25px 30px 15px;
    margin: 5%;
    text-align: left;
}
.iconify.iconify--bi{
    margin-right: 5px;
}
.retailerName{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
}
.retailerDetails{
    font-size: 14px;
}
.retailerDetails .addressIcon{
    cursor:pointer;
    color: red;
}
.retailerGST{
    font-size: 10px;
    color: red;
    text-align: center;
    font-weight: 700;
    font-style: italic;
}
