.buySellHeader{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 5%;
}
.basic-multi-select{
    display: inline-block;
    width: 66%;
    margin-bottom: 5%;
}
.filtersDropdown{
    margin-top: -7px !important;
}
.buySell.main-outer-container .filterSection span{
    color: #007bff;
}
.basic-multi-select{
    font-size: 12px;
    font-weight: 600;
}
.wantRetailer{
    float: right;
    font-size: 12px;
    color: #007bff;
}
.wantRetailer:hover{
    text-decoration: underline;
}
