.coinBigImg{
    margin-top: 5%;
    margin-bottom: 10%;
    border: 1px solid silver;
}
.coinSmallImg img{
    border: 1px solid silver;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    padding: 1%;;
}
.coinText{
    font-size: 14px;
    font-style: oblique;
    font-weight: 500;
    /* margin-top: 12.5% !important; */
}
/* .magnifier img{
    height: 200px;
    width: 200px;
} */