#tooltip {
    position: absolute;
    left: -62px;
    bottom: 70px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    display: inline-block;
    opacity: 0.8;
    z-index: 99999;
    width: 175px;
}

#tooltip.top {
    margin-top: -5px;
}

#tooltip .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}

#tooltip.top .tooltip-arrow {
    top: auto;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

#tooltip .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
}

#tooltip .tooltip-label {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
}