/* Footer */
/* .footer{
    background-color: #111111;
    color: #FFC451;  
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;  
  }
  ul{
    list-style-type: none;
    margin-top: 10px;
  }
  li{
    display: inline-block;
    padding-left: 5%;
  }
  li:first-child{
    padding-left: 0px;
  } */
  /* li>a{
    text-decoration: none;
    color: #FFC451;
  } */


  .footer-basic {
    position: fixed;
    padding:15px 0;
    background-color:#ffffff;
    color:#4b4c4d;
    width: 100%;
    bottom: 0;
    margin-bottom: 0;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
  .footer-basic ul {
    padding:0;
    list-style:none;
    text-align:center;
    font-size:11px;
    line-height:2.6;
    margin-bottom:0;
  }
  
  .footer-basic li {
    padding:0 10px;
  }
  
  .footer-basic ul span {
    cursor: pointer;
    color:inherit;
    text-decoration:none;
    opacity:0.8;
  }
  
  .footer-basic ul span:hover {
    opacity:1;
  }
  
  .footer-basic .social {
    text-align:center;
    padding-bottom:10px;
  }
  
  .footer-basic .social > span {
    font-size:24px;
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    border-radius:50%;
    border:1px solid #ccc;
    margin:0 8px;
    color:inherit;
    opacity:0.75;
  }
  
  .footer-basic .social > span:hover {
    opacity:0.9;
  }
  
  .footer-basic .copyright {
    margin-top:8px;
    text-align:center;
    font-size:13px;
    color:#aaa;
    margin-bottom:0;
  }
  