.mobileApp{
    background-image: url('../../assets/images/mobile_app_background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;  
    
}
.mobileApp .container{
    padding: 25% 2%;
    font-size: 50px;
    font-weight: 700;
    color: yellow;
}