.todaysRate .header{
    font-size: 16px;
    font-weight: 700;
    color: forestgreen;
}
.todaysRate .date{
    font-size: 12px;
    color: black;
    font-weight: 600;
}
.rateTableContainer{
    max-height: 500px;
    overflow-y: auto;
}
.rateTable{
    font-size: 14px;
    border: 1px solid black !important;
    background-color: antiquewhite;
}
.rateTable thead{
    color: red;
}
.rateTable tbody{
    font-weight: 500;
}
.todaysRate.main-outer-container{
    padding-top: 2% !important;
}
.todaysRate{
    background-image: url('../../assets/images/background_image_1.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-attachment: fixed;  
}
.toggleView button.btn{
    border-radius: 20px;
    padding: 6px;
    margin-left: 1%;
}
.toggleView button.btn:focus{
    box-shadow: none;
}
.toggleView button.btn-warning{
    color: crimson;
}
.toggleView button.btn-secondary{
    color: white;
}